import { Route } from './'
import { lazy, Suspense } from 'react'
import Loading from "../components/my/MySpin"
import AUP from '../pages/other/aup'

const Privacy = lazy(() => import('../pages/other/privacy'))
const Terms = lazy(() => import('../pages/other/terms'))
const Contact = lazy(() => import('../pages/other/contact'))

export const other: Route[] = [
    { 
        path: '/other/privacy', 
        element: (
            <Suspense fallback={<Loading />}>
                <Privacy />
            </Suspense>
        ),
    },
    { 
        path: '/other/terms', 
        element: (
            <Suspense fallback={<Loading />}>
                <Terms />
            </Suspense>
        ),
    },
    { 
        path: '/other/contact', 
        element: (
            <Suspense fallback={<Loading />}>
                <Contact />
            </Suspense>
        ),
    },
    { 
        path: '/other/aup', 
        element: (
            <Suspense fallback={<Loading />}>
                <AUP />
            </Suspense>
        ),
    },
]
