import React from 'react';
import MyImage from './MyImage';

interface MyAvatarProps {
  src?: string
  size?: number
  className?: string
  style?: React.CSSProperties
}

const MyAvatar: React.FC<MyAvatarProps> = ({ src = "", size = 10, className, style }) => {
  // other data
  const insteadUrl = "https://info-catnip-prod-public.s3.ap-east-1.amazonaws.com/DefaultAvatar.png"
  
  return (
    <section style={{ width: size / 4 + 'rem' }} className='aspect-square flex'>
      <MyImage 
        src={src}
        subSrc={insteadUrl}
        style={style}
        className={[className, 'w-full h-full aspect-square object-cover cursor-pointer rounded-full bg-primary'].join(' ')}
      />
    </section>
  )
}

export default MyAvatar;