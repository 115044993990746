// /src/api/chapter/delete.ts
import { ChapterDeleteDto } from "../../vo/chapter.vo"
import { http } from "../request"

export const chapterDeleteApi = async (id: number, accessToken: string) => {
    return await http.delete<ChapterDeleteDto>(`/chapter/${id}`, {
        headers: {
            Authorization: "Bearer " + accessToken
        }
    })
}
