import React, { memo, useContext } from 'react'
import { ViewportProviderContext } from '../../providers/ViewportProvider'
import Banner from '../../components/base/Banner'

const AUP: React.FC = memo(() => {
  const { notDesktop } = useContext(ViewportProviderContext)

  return (
    <>
      <Banner title="使用條款政策 (AUP)" />
      <main className={notDesktop ? 'py-10 px-10' : 'pt-16 px-[20%] pb-40'}>
        <h4>一、目的</h4>
        <li>本使用條款政策（"AUP"）概述了使用本平台（一個數位文學收藏品平台）的規則和指南。使用本平台即表示您同意遵守本 AUP。</li>

        <h4>二、用戶行為</h4>
        <p className='text-[#708878] font-semibold'>用戶必須：</p>
        <li>尊重知識產權</li>
        <li>維持適當的內容標準</li>
        <li>進行公平和道德的實踐</li>
        <li>保護用戶隱私和數據</li>

        <h4>三、禁止行為</h4>
        <p className='text-[#708878] font-semibold'>以下行為在本平台上嚴格禁止：</p>
        <li>未經適當授權上傳或銷售受版權保護的材料</li>
        <li>創建或分發惡意內容，包括惡意軟件或網絡釣魚企圖</li>
        <li>進行欺詐交易或虛假陳述收藏品</li>
        <li>騷擾、欺凌或威脅其他用戶</li>
        <li>試圖操縱平台功能或干擾其他用戶的體驗</li>

        <h4>四、內容準則</h4>
        <p className='text-[#708878] font-semibold'>本平台上的所有內容必須：</p>
        <li>為原創或已獲得適當授權</li>
        <li>不得包含露骨的性內容、極端暴力或仇恨言論</li>
        <li>準確描述和表達</li>
        <li>遵守所有適用的法律法規</li>

        <h4>五、知識產權</h4>
        <li>用戶必須尊重版權法，只能上傳他們有權銷售或分發的內容。The Catnip Limited 保留刪除任何違反知識產權的內容的權利。</li>

        <h4>六、隱私和數據保護</h4>
        <li>用戶必須尊重他人的隱私並遵守適用的數據保護法。未經同意收集或分享個人信息是被禁止的。</li>

        <h4>七、執行</h4>
        <p className='text-[#708878] font-semibold'>本平台保留以下權利：</p>
        <li>刪除違反本 AUP 的內容</li>
        <li>暫停或終止重複違反 AUP 的用戶帳戶</li>
        <li>在發生違法行為時與執法機構合作</li>

        <h4>八、禁止的行業、商品、活動和內容</h4>
        <p className='text-[#708878] font-semibold'>以下行業在本平台上嚴格禁止：</p>
        <li>非法藥物和麻醉品</li>
        <li>武器和爆炸物</li>
        <li>成人娛樂和色情內容</li>
        <li>賭博和博彩服務</li>
        <li>多層次傳銷計劃</li>

        <p className='text-[#708878] font-semibold'>禁止的商品：</p>
        <li>仿冒或被盜物品</li>
        <li>瀕危物種產品</li>
        <li>危險物品</li>
        <li>非法或處方藥物</li>
        <li>武器或武器配件</li>

        <p className='text-[#708878] font-semibold'>禁止的活動：</p>
        <li>洗錢</li>
        <li>恐怖主義融資</li>
        <li>身份盜竊或欺詐</li>
        <li>網絡犯罪或黑客服務</li>
        <li>金字塔計劃或龐氏騙局</li>

        <p className='text-[#708878] font-semibold'>禁止的內容：</p>
        <li>露骨的性內容或色情內容</li>
        <li>血腥暴力</li>
        <li>仇恨言論或促進歧視的內容</li>
        <li>非法活動的指導內容</li>
        <li>未經同意的個人信息或數據</li>
        <li>誹謗或中傷性內容</li>
        <li>侵犯知識產權的內容</li>
        <li>惡意軟件、病毒或其他有害代碼</li>

        <p className='mt-4'>本平台保留拒絕向任何參與這些禁止的行業、商品、活動或內容的個人或實體提供服務的權利。違反這些禁令的用戶可能會被暫停或終止帳戶，並可能面臨法律後果。</p>

        <h4>九、違規報告</h4>
        <li>我們鼓勵用戶報告任何違反本 AUP 的行為，包括禁止的行業、商品、活動或內容的實例。請聯繫我們的支援團隊進行報告。</li>

        <h4>十、AUP 的變更</h4>
        <li>The Catnip Limited 可能隨時修改本 AUP。用戶將收到重大變更的通知，繼續使用平台即表示接受更新後的 AUP。</li>

        <h4>十一、聯絡資訊</h4>
        <li>如對本 AUP 有任何問題或疑慮，請聯絡 <a href="mailto:info@thecatnip.com" className="text-blue-600 hover:underline">info@thecatnip.com</a></li>
      </main>
    </>
  )
})

export default AUP
