import React, { memo, useContext, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { EffectCoverflow, Navigation } from 'swiper/modules';
import { Collectible } from './Collectible'
import { CollectibleProps } from '../../vo/collectible.vo';
import { ViewportProviderContext } from '../../providers/ViewportProvider';

interface CollectibleCarouselProps {
    data: CollectibleProps[]
    navigation?: {
        color?: string
    }
}

export const CollectibleCarousel: React.FC<CollectibleCarouselProps> = memo((props) => {
    // props
    const { data, navigation } = props

    // context
    const { notDesktop } = useContext(ViewportProviderContext)

    // responsive data
    const [currentIndex, setCurrentIndex] = useState<number>(2)

    // method: 监听轮播图变化
    const handleSlideChange = (swiper: any) => {
        setCurrentIndex(swiper.activeIndex)
    }

    return (
        <main 
            className={[
                'w-full m-auto',
                notDesktop ? 'py-6' : 'py-16 min-w-[960px]'
            ].join(' ')}
        >
            <section
                className={[
                    'w-auto',
                    notDesktop ? '' : 'px-[10%]'
                ].join(' ')}
            >
                <Swiper
                    effect={'coverflow'}
                    grabCursor={true}
                    centeredSlides={true}
                    initialSlide={(data && data.length < 5) ? 0 : 2}
                    slidesPerView={notDesktop ? 3: 5}
                    slidesPerGroup={1}
                    onSlideChange={handleSlideChange}
                    coverflowEffect={{
                        rotate: 30,
                        stretch: notDesktop ? -50 : -70,
                        depth: notDesktop ? 700 : 500,
                        modifier: 1,
                        slideShadows: false,
                    }}
                    navigation={{
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    }}
                    className="mySwiper"
                    modules={[EffectCoverflow, Navigation]}
                >
                    {
                        data && data.map((collectible, index) => {
                            return (
                                <SwiperSlide key={index} className='collectible-slide relative'>
                                    <section 
                                        style={{ 
                                            opacity: (index >= currentIndex - 2 && index <= currentIndex + 2) ? "1" : "0" 
                                        }}
                                        className='m-auto h-full aspect-[319/418] transition duration-500 absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2'
                                    >
                                        <Collectible {...collectible}/>
                                    </section>
                                </SwiperSlide>
                            )
                        })
                    }
                    <div style={{ backgroundColor: navigation?.color || '#102e24' }} className='swiper-button-next transition duration-500'></div>
                    <div style={{ backgroundColor: navigation?.color || '#102e24' }} className='swiper-button-prev transition duration-500'></div>
                </Swiper>
            </section>
        </main>
    )
})
