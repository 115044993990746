import React, { Fragment, ReactElement, ReactNode, createContext, forwardRef, memo, useCallback, useContext, useImperativeHandle, useState } from 'react'
import { ViewportProviderContext } from '../../providers/ViewportProvider'
import MyButton from './MyButton'

export interface MyTab {
    id: string
    name: string
    element: JSX.Element
}

interface MyTabsProps {
    tabs?: MyTab[],
    type?: 'normal' | 'steps'
    children?: ReactElement[]
    headerSlot?: boolean
    leftSlot?: JSX.Element,
    tabsClassName?: string
}

export type MyTabsRef = {
    tabs?: MyTab[]
    selectedTab: MyTab | null
    setSelectedTab: React.Dispatch<React.SetStateAction<MyTab| null>>
}

interface MyTabsItemProps {
    title?: string
    children?: ReactNode
    headerSlot?: boolean
}

type MyTabsContextType = {
    handleNextStep: Function
}

export const MyTabsContext = createContext<MyTabsContextType>({} as MyTabsContextType)

const MyTabs = forwardRef<MyTabsRef, MyTabsProps>((props, ref) => {
    // props
    const { tabs, leftSlot, tabsClassName, type = 'normal' } = props
    const headerSlot = props?.children?.find(child => child.props.headerSlot)
    const children = props?.children?.filter(child => !child.props.headerSlot)

    // responsive data
    const [selectedTab, setSelectedTab] = useState<MyTab | null>(tabs?.length ? tabs[0] : null)
    const [selectedIndex, setSelectedIndex] = useState<number>(0)

    // expose
    useImperativeHandle(ref, () => ({
        tabs,
        selectedTab,
        setSelectedTab,
    }))

    // context
    const { isDesktop } = useContext(ViewportProviderContext);

    // method: to next step
    const handleNextStep = useCallback(() => {
        setSelectedIndex(state => state + 1)
    }, [])

    return (
        <MyTabsContext.Provider value={{ handleNextStep }}>
            <main>
                {/*
                    1. render steps by props（不推荐，难以管理状态，并且会出现 setState 无法带动 render 的情况）
                    示例: 
                        const tabs: MyTabs = [...]
                        const leftSlot = <>...</>
                        <MyTabs tabs={tabs} leftSlot={leftSlot}/>
                */}
                {
                    (tabs && tabs.length > 0) && (
                        <ul className={['flex gap-10 py-8 overflow-auto', tabsClassName].join(' ')}>
                            {
                                tabs.map((tab, index) => (
                                    <li 
                                        key={index}
                                        onClick={() => setSelectedTab(tab)}
                                        className='flex gap-5 items-center cursor-pointer whitespace-nowrap'
                                    >
                                        <div 
                                            className='w-8 h-8 rounded-full text-primary text-ms flex justify-center items-center'
                                            style={{
                                                backgroundColor: tab.id === selectedTab?.id ? "#102e24" : "#EBEEE8",
                                                color: tab.id === selectedTab?.id ? "white" : "#102e24"
                                            }}
                                        >{ index + 1 }</div>
                                        <div className='text-sm text-primary'>{ tab.name }</div>
                                    </li>
                                ))
                            }
                        </ul>
                    )
                }
                {/* 
                    2. render steps by MyTabs.Item（推荐）
                    示例: 
                        <MyTabs>
                            <MyTabs.Item headerSlot>...</MyTabs.Item>
                            <MyTabs.Item>...</MyTabs.Item>
                            <MyTabs.Item>...</MyTabs.Item>
                            ...
                        </MyTabs>
                */}
                {
                    children && (
                        <ul className={[tabsClassName, 'flex justify-center items-center h-16'].join(' ')}>
                            {   
                                children.map((child, index) => (
                                    <li key={index} onClick={() => setSelectedIndex(index)}>
                                        {
                                            type === 'steps' && (
                                                <section className='flex gap-2 items-center cursor-pointer whitespace-nowrap'>
                                                    <div 
                                                        className='w-8 h-8 rounded-full text-primary text-ms flex justify-center items-center'
                                                        style={{
                                                            backgroundColor: index === selectedIndex ? "#102e24" : "#EBEEE8",
                                                            color: index === selectedIndex ? "white" : "#102e24"
                                                        }}
                                                    >{ index + 1 }</div>
                                                    <div className='text-sm text-primary'>{ child.props.title }</div>
                                                </section>
                                            )
                                        }
                                        {
                                            type === 'normal' && (
                                                <MyButton
                                                    type={ index === selectedIndex ? 'primary' : 'default' }
                                                    size='large'
                                                >{ child.props.title }</MyButton>
                                            )
                                        }
                                    </li>
                                ))
                            }
                        </ul>
                    )
                }
                {/* 顶部插槽 */}
                { headerSlot }
                <article className={`flex ${isDesktop? "flex-row gap-12 items-stretch":"flex-col gap-10 items-center"} justify-center w-full`}>
                    {/* 左侧插槽 */}
                    { leftSlot }
                    {/* 1. render by props */}
                    { selectedTab?.element }
                    {/* 2. render by MyTabs.Item */}
                    { children?.find((child, index) => index === selectedIndex) }
                </article>
            </main>
        </MyTabsContext.Provider>
    )
});

const MyTabsItem: React.FC<MyTabsItemProps> = memo(({ title, children }) => {
    return (
        <>
            { 
                Array.isArray(children)
                    ? children.map((child, index) => <Fragment key={index}>{ child }</Fragment>)
                    : children
            }
        </>
    )
})

interface IMyTabs extends React.ForwardRefExoticComponent<MyTabsProps & React.RefAttributes<MyTabsRef>> {
  Item: React.FC<MyTabsItemProps>
}

(MyTabs as IMyTabs).Item = MyTabsItem

export default MyTabs as IMyTabs