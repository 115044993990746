import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios"
import { message } from "antd"

export interface ErrorResponse {
    error: string
    info: string
}

class Http  {
    private readonly timeout: number = 10000
    private readonly baseURL: string = process.env.REACT_APP_API_URL || ""
    private readonly instance: AxiosInstance

    constructor() {
        this.instance = axios.create({
            baseURL: this.baseURL,
            timeout: this.timeout,
            headers: {
                "Content-Type": "application/json"
            }
        })
        this.instance.interceptors.response.use(
            this.handleResponseSuccess,
            this.handleResponseError
        )
    }

    // 响应成功拦截器
    handleResponseSuccess(response: AxiosResponse<any>): AxiosResponse<any> {
        if(response.data.data) return response.data.data
        else return response.data 
    }

    // 响应失败拦截器
    handleResponseError(error: AxiosError): ErrorResponse {
        // const status = error.response?.status
        const data = error.response?.data as ErrorResponse
        // const errorCode = data.error
        const errorInfo = data.info || `${error.status} - ${data.error }` 
        message.error(errorInfo)
        return data
    }

    public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this.instance.get<T>(url, config) as Promise<T>
    }

    public post<T>(url: string, body: any, config?: AxiosRequestConfig): Promise<T> {
        return this.instance.post<T>(url, body, config) as Promise<T>
    }

    public put<T>(url: string, body: any, config?: AxiosRequestConfig): Promise<T> {
        return this.instance.put<T>(url, body, config) as Promise<T>
    }

    public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this.instance.delete<T>(url, config) as Promise<T>
    }
}

export const http = new Http()